<template>
  <CHeader fixed colorScheme="dark" class="bg-primary">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      :src="require('../assets/imgs/logo_sosclick.png')"
      style="object-fit: contain; max-width: 100px"
      alt="SOSclick"
    />
    <CHeaderNav class="mr-auto">
<!--       <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CButton @click="switchDashboardMarkers('general')" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMarkers == 'general' ? '':'outline'">
        <font-awesome-icon icon="home" />
        General
      </CButton>
      <CButton @click="switchDashboardMarkers('tracking')" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMarkers == 'tracking' ? '':'outline'" >
        <font-awesome-icon icon="search-location" />
        Tracking
      </CButton>
      <CButton @click="switchDashboardMarkers('cameras')" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMarkers == 'cameras' ? '':'outline'" >
        <font-awesome-icon icon="video" />
        Cameras
      </CButton>
      <CButton @click="switchDashboardMarkers('gps')" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMarkers == 'gps' ? '':'outline'" >
        <font-awesome-icon icon="location-arrow" />
        GPS
      </CButton>
        
<!--       <CDropdown
        color="primary"
        toggler-text="Segmentación" 
        class="m-2 dropclusters-header"
      >
        <CDropdownItem @click="filter.mujeres = !filter.mujeres">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.mujeres" style="height: 20px;"/> <p class="mb-0">Mujeres</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="filter.comercio = !filter.comercio">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.comercio" style="height: 20px;"/> <p class="mb-0">Comercio</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="filter.hogar = !filter.hogar">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.hogar" style="height: 20px;"/> <p class="mb-0">Hogar</p>
          </div>
        </CDropdownItem>
        <div class="mx-1">
          <CButton
            color="primary"
            size="sm"
            class="w-100"
            @click="$events.fire('filterSegments', filter)"
          >
            Aplicar
          </CButton>
        </div>
      </CDropdown>
      <CButton @click="toggleMujeres()" class="mx-1" color="success" size="sm" :variant="filter.mujeres ? '':'outline'">
        <font-awesome-icon icon="female" />
        Mujeres
      </CButton>
      <CButton @click="toggleComercio()" class="mx-1" color="success" size="sm" :variant="filter.comercio ? '':'outline'">
        <font-awesome-icon icon="building" />
        Comercio
      </CButton>
      <CButton @click="toggleHogar()" class="mx-1" color="success" size="sm" :variant="filter.hogar ? '':'outline'">
        <font-awesome-icon icon="home" />
        Hogar
      </CButton>
      <CButton @click="toggleBotones()" class="mx-1" color="success" size="sm" :variant="$store.state.showBotones ? '':'outline'">
        <font-awesome-icon icon="blind" />
        Adulto Mayor
      </CButton> -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
         </CHeaderNavItem>
         -->
     
<!--       <div class="d-flex align-items-center">
        <div>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Sonido Alertas</strong></p>
        </div>
        <CSwitch
          class="mr-2"
          size="sm"
          color="primary"
          shape = "pill"
          @update:checked="toggleAlarmSound"
          :checked="$store.state.alarmSound"
        />
      </div>
      <div class="d-flex align-items-center">
        <div>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>{{$store.state.showVideo ? 'Atención Primaria' : 'Seguridad'}}</strong></p>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.showVideo ? 'Videoconferencia Automática' : 'Monitoreo Silencioso'}}</p>
        </div>
        <CSwitch
          class="mr-2"
          size="sm"
          color="primary"
          shape = "pill"
          :checked.sync="$store.state.showVideo"
        />
      </div> -->
          <CSpinner
            v-if="loader"
            color="white"
            style="width:20px;height:20px;"
            />
          <div style="display: flex; align-items: center" v-if="$store.state.dashboardMarkers == 'tracking' && !loader">
            <p style="display: block; margin-bottom: 0;">{{trackingType ? 'Tracking Tiempo Real' : 'Tracking Histórico'}}:</p>
            <CInputCheckbox
              alignVertical="center"
              alignHorizontal="center"
              variant="opposite"
              color="dark"
              :checked.sync="trackingType"
              class="ml-3 checkbox"
              style="display: flex; align-items: center"
            />
          </div>
      <CButton v-if="$store.state.dashboardMarkers == 'tracking'" color="primary" @click="openFilterTrackingModal">Filtrar Rutas</CButton>
      <CButton v-if="$store.state.dashboardMarkers == 'tracking' && trackingType" color="primary" @click="openModalDeleteAllRtTracking">Borrar Datos Tiempo Real</CButton>
      <OptionsDropdown />
      <AlertasDropdown />
      <!-- <TheHeaderDropdownAccnt/> -->
      <CButton @click="toogleGrilla()" color="primary" size="sm" :variant="$store.state.grillaMonitoreo ? '':'outline'">
        <img class="white-color-image-overlay" style="margin-bottom: 2px;max-height: 20px; max-width: 20px;" :src="require('../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/>
        <CBadge v-if="$store.state.tuyaCameras.length" color="success" class="ml-auto mr-2" style="position: absolute;top: 50%;margin-top: -16px; border-radius: 10px; width: 10px; height:10px;"><span></span></CBadge>
      </CButton>
    </CHeaderNav>
<!--     <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import AlertasDropdown from './AlertasDropdown'
import OptionsDropdown from './OptionsDropdown'
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    AlertasDropdown,
    OptionsDropdown
  },
  data(){
    return {
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true
      },
      trackingType: true,
      loader: false
    }
  },
  methods:{
    openModalDeleteAllRtTracking() {
      this.$store.commit('setModalDeleteAllRtTracking', true)
    },
    openFilterTrackingModal() {
      this.$store.commit('setModalTrackingFilter', true)
    },
    switchDashboardMarkers(markers){
      this.$store.commit('setDashboardMarkers', markers)
    },
    tooglePanel(){
      this.$store.commit('setPanelShow', !this.$store.state.monitorPanelShow)
    },
    toggleMujeres(){
      this.filter.mujeres = !this.filter.mujeres
      this.$events.fire('filterSegments', this.filter)
    },
    toggleComercio(){
      this.filter.comercio = !this.filter.comercio
      this.$events.fire('filterSegments', this.filter)
    },
    toggleHogar(){
      this.filter.hogar = !this.filter.hogar
      this.$events.fire('filterSegments', this.filter)
    },
    toggleBotones(){
      this.$store.commit('setShowBotones', !this.$store.state.showBotones)
    },
    toggleAlarmSound(){
      this.$store.commit('setAlarmSound', !this.$store.state.alarmSound)
    },
    toogleGrilla(){
      this.$router.push('/monitors')
      this.$store.commit('toogleGrilla', !this.$store.state.grillaMonitoreo)
    }
  },
  watch: {
    trackingType(value) {
      this.$store.commit('setTrackingType', value)
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
      }, 500);
    }
  }
}
</script>