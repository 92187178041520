<template>
  <CFooter>
    <div>
      <a href="https://coreui.io" target="_blank">SOS click</a>
      <span class="ml-1">&copy; 2020 Natural Phone.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>