
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Mapa',
        to: '/dashboard',
        icon: {
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Alertas',
        to: '/alertas',
        icon: {
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cuadrantes',
        to: '/cuadrantes',
        icon: {
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Datos Usuarios',
        to: '/users',
        icon: {
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Publicidad',
        to: '/publicidad',
        icon: {
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Salir',
        to: '/login',
        icon: 'cil-account-logout'
      }
    ]
  }
]