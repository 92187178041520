<template>
  <CSidebar 
  style="position:relative"
    colorScheme="light"
    fixed 
    :minimize="minimize"
    :show.sync="show"
  >
  <img @click="show = false" class="close" src="../assets/icons/svg/Close.svg" alt="cerrar">
    <CSidebarNav>
      <CSidebarNavItem
        name='Mapa'
        to='/dashboard'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }"/>
      <CSidebarNavItem
        name='Alertas'
        to='/alertas'
        :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_cuadrantes'])"
        name='Cuadrantes'
        to='/cuadrantes'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }"/>
<!--       <CSidebarNavItem
        name='Grupos Operacionales'
        to='/operational-groups'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }"/> -->
      <CSidebarNavDropdown
        name="Usuarios Panel"
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
        }">
        <CSidebarNavItem
          v-if="checkPermissions(['isSuperAdmin', 'view_user_admin'])"
          name='Usuarios SuperAdmin'
          to='/superadmin-users'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }"/>
        <CSidebarNavItem
          v-if="checkPermissions(['view_user_admin'])"
          name='Usuarios Panel'
          to='/admin-users'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }"/>
      </CSidebarNavDropdown>
      <CSidebarNavItem
        v-if="checkPermissions(['view_user_app'])"
        name='Usuarios'
        to='/users'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }"/>
<!--       <CSidebarNavItem
        v-if="checkPermissions(['view_publicidad'])"
        name='Publicidad'
        to='/publicidad'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }"/> -->
        <CSidebarNavDivider class="my-2" />
      <CSidebarNavItem
        v-if="role == 9"
        name="Configuración"
        to="/configuration"
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/icons/svg/settingsblack.svg'),
        }"
      />
      <CSidebarNavDivider class="my-2"/>
      <div @click="logOut">
      <CSidebarNavItem
        name='Salir'
        addLinkClasses="logout-click-handler"
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }"/>
      </div>
    </CSidebarNav>
<!--     <CSidebarBrand 
      :imgFull="{ width: 118, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :imgMinimized="{ width: 60, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :wrappedInLink="{ href: '/', target: '_blank'}"
    /> -->
    <!-- <CRenderFunction flat :content-to-render="nav"/> -->
<!--     <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    /> -->
  </CSidebar>
</template>

<script>
import nav from './_nav'
import checkPermissions from '../helpers/userPermissions'

export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav,
      show: false,
      role: null,
    }
  },
  watch:{
    $route (to, from){
      this.show = false
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })

    this.role = JSON.parse(localStorage.getItem("userData"))?.type;
  },
  methods:{
    checkPermissions,
     logOut(){
      console.log("Salir?")
       localStorage.removeItem('userToken')
      this.$router.push('Login')
    }
  }
}
</script>

<style scoped>
.close{
  width: 60px;
   position:absolute;
   top: 10px;
   right: 0;  
   cursor:pointer; 
}
</style>
